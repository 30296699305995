/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import { MdOutlinePersonAddAlt1 } from "react-icons/md";
import { CiSearch } from "react-icons/ci";
import { HiOutlineDotsVertical } from "react-icons/hi";
import DashboardLayout from "../components/shared/DashboardLayout";
import { FaStar } from "react-icons/fa6";
import AddStudentModal from "../components/modals/AddStudentModal";
import { useNavigate } from "react-router-dom";
import useGetStudents from "../hooks/useGetStudents";
import { skelton_data } from "../staticData/SkeltonData";
import { CardSkelton_4 } from "../components/shared/Skeltons";
import Paginations from "../components/shared/Paginations";
import DeleteModal from "../components/modals/DeleteModal";
import GlobalContext from "../context/GlobalContext";
import ShowMessage from "../components/shared/ShowMessage";
import { TbCloudUpload } from "react-icons/tb";
import BulkUpload from "../components/modals/BulkUpload";
import AppHead from "../seo/AppHead";
import { debounce } from "lodash";

const StudentPage = () => {
  return (
    <>
      <AppHead title="Students - ELEMENT EDUCATION" />
      <DashboardLayout>
        <div className="bg-backgroundColor px-5 py-2 flex flex-col w-full h-auto">
          <MainLayout />
        </div>
      </DashboardLayout>
    </>
  );
};

export default StudentPage;

function MainLayout() {
  const Navigate = useNavigate();
  const [showMenuIndex, setShowMenuIndex] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenModalBulkUpload, setIsOpenModalBulkUpload] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const { updateResponse } = useContext(GlobalContext);
  const [query, setQuery] = useState("");

  const {
    GetStudents,
    studentsData,
    studentLoading,
    studentMessage,
    currentPageStudent,
    totalPagesStudent,
    setCurrentPageStudent
  } = useGetStudents();

  const debouncedSearch = useCallback(
    debounce((value) => {
      setCurrentPageStudent(1);
      GetStudents(`?page=${currentPageStudent}&search=${value}`);
    }, 1000),
    []
  );

  useEffect(() => {
    GetStudents(`?page=${currentPageStudent}&search=${query}`);
  }, [currentPageStudent, updateResponse]);

  const handleChange = (e) => {
    const { value } = e.target;
    setQuery(value);
    debouncedSearch(value);
  };

  const handlePageChange = (event, value) => {
    setCurrentPageStudent(value);
  };

  const handleMenu = (item, e, index) => {
    e.stopPropagation();
    setShowMenuIndex(index);
    setSelectedStudent(item);
  };

  const NavigateToDetailsPage = (item) => {
    Navigate(`/students/${item.id}`);
  };

  const NavigateToAnalyticsPage = (item) => {
    Navigate(`/students/analytics/${item.id}`);
  };

  const dropdownRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowMenuIndex(null);
        setSelectedStudent(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <>
      <div className="flex md:flex-row xs:flex-col md:gap-0 xs:gap-2 md:items-center justify-between w-full">
        <div>
          <h1 className="2xl:font-[700] 2xl:text-[36px] lg:font-[500] lg:text-[30px] font-roboto text-[#263238]">
            Students
          </h1>
          <span className="2xl:text-[18px] font-[400] lg:text-[15px] font-roboto text-[#7A7A7A]">
            Show all data here
          </span>
        </div>
        <div className="flex items-center gap-3 xs:justify-end md:justify-start">
          <div className="flex items-center border bg-white border-[#DCDCDC] hover:border-[#017663] focus-within:border-[#017663] rounded-[10px] p-2 w-[280px] h-[50px] ">
            <input
              type="search"
              value={query}
              onChange={handleChange}
              placeholder="Search..."
              className="outline-none border-none w-[100%] h-full bg-transparent px-3"
            />
            <CiSearch className="text-[20px] text-[#C1C1C1]" />
          </div>
          {/* <div className="custom-select w-[160px] h-[50px]">
            <select>
              <option selected disabled>
                Choose grade
              </option>
              <option value="0">Grade: 0</option>
              <option value="1">Grade: 1</option>
              <option value="1">Grade: 2</option>
            </select>
          </div> */}
          {/* <div
            className="flex items-center border bg-transparent border-[#017663] rounded-[6px] px-5 gap-3 lg:min-w-[150px] xs:w-auto h-[50px] cursor-pointer text-[#017663]"
            onClick={() => {
              setIsOpenModalBulkUpload(true);
            }}
          >
            <TbCloudUpload className="text-[18px] text-[#017663]" />
            <p className="2xl:font-[700] 2xl:text-[14px] lg:font-[500] lg:text-[14px] font-roboto lg:block xs:hidden">
              Bulk Upload
            </p>
          </div> */}
          <div
            onClick={() => {
              setIsOpenModal(true);
              setIsEditMode(false);
            }}
            className="flex items-center border bg-[#017663] border-none rounded-[6px] px-5 gap-3 lg:min-w-[180px] xs:w-auto h-[50px] cursor-pointer text-white"
          >
            <MdOutlinePersonAddAlt1 className="text-[18px] text-white" />
            <p className="2xl:font-[700] 2xl:text-[15px] lg:font-[500] lg:text-[14px] font-roboto lg:block xs:hidden">
              Add New Student
            </p>
          </div>
        </div>
      </div>
      {studentLoading ? (
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 gap-4 mt-5">
          {skelton_data.slice(0, 8).map((_, index) => (
            <CardSkelton_4 key={index} />
          ))}
        </div>
      ) : (
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 gap-4 mt-5">
          {studentsData?.results?.map((item, index) => (
            <div key={index}>
              <StudentCard
                key={index}
                item={item}
                index={index}
                show={showMenuIndex === index}
                handleMenu={(e) => handleMenu(item, e, index)}
                NavigateToDetailsPage={() => NavigateToDetailsPage(item)}
                NavigateToAnalyticsPage={() => NavigateToAnalyticsPage(item)}
                setDeleteModal={setDeleteModal}
                setIsEditMode={setIsEditMode}
                setIsOpenModal={setIsOpenModal}
                setShowMenuIndex={setShowMenuIndex}
                dropdownRef={dropdownRef}
              />
            </div>
          ))}
        </div>
      )}
      {!studentLoading && studentMessage && (
        <div className="w-full h-[50vh] flex justify-center items-center">
          <ShowMessage message={studentMessage} />
        </div>
      )}

      <div className="w-full flex justify-center items-center mt-5">
        <Paginations
          totalPages={totalPagesStudent}
          currentPage={currentPageStudent}
          handlePageChange={handlePageChange}
        />
      </div>

      <AddStudentModal
        setIsOpenModal={setIsOpenModal}
        isOpenModal={isOpenModal}
        isEditMode={isEditMode}
        setIsEditMode={setIsEditMode}
        selectedStudent={selectedStudent}
        setSelectedStudent={setSelectedStudent}
      />

      <BulkUpload
        isOpenModal={isOpenModalBulkUpload}
        setIsOpenModal={setIsOpenModalBulkUpload}
        type="students"
      />

      <DeleteModal
        setIsOpenModal={setDeleteModal}
        isOpenModal={deleteModal}
        setSelected={setSelectedStudent}
        url={`/api/student/delete?id=${selectedStudent?.id}`}
      />
    </>
  );
}

function StudentCard({
  item,
  index,
  show,
  handleMenu,
  NavigateToDetailsPage,
  NavigateToAnalyticsPage,
  setDeleteModal,
  setIsEditMode,
  setIsOpenModal,
  setShowMenuIndex,
  dropdownRef
}) {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  return (
    <div
      className="w-full bg-[#FFFFFF] rounded-[10px] p-[20px] flex flex-col items-center justify-center relative cursor-pointer border border-transparent hover:border-[#017663]"
      key={index}
      onClick={NavigateToDetailsPage}
    >
      <div className="flex flex-col gap-1 items-center justify-center mb-4">
        {item.profile && (
          <img
            src={`${BASE_URL}${item?.profile}` || "/avatar_dummy.png"}
            alt=""
            className="w-[60px] h-[60px] rounded-full"
          />
        )}
        {item.profile === null && (
          <img
            src={"/avatar_dummy.png"}
            alt=""
            className="w-[60px] h-[60px] rounded-full"
          />
        )}
        <span className="2xl:text-[20px] font-[500] lg:text-[15px] font-roboto text-[#1C1D1E]">
          {item.name}
        </span>
        <span className="2xl:text-[16px] font-[400] lg:text-[12px] font-roboto text-[#7A7A7A]">
          {item.student_id}
        </span>
      </div>
      <div className="flex gap-2">
        <div className="flex flex-col items-center">
          <span className="text-[12px] font-[400] font-roboto text-[#7A7A7A] text-center">
            Total Assesments
          </span>
          <span className="2xl:text-[20px] font-[400] lg:text-[15px] font-roboto text-[#1C1D1E]">
            {item.total_assessments || "0"}
          </span>
        </div>
        <div className="border border-[#DCDCDC]"></div>
        <div className="flex flex-col items-center">
          <span className="text-[12px] font-[400] font-roboto text-[#7A7A7A] text-center">
            On Going Assesments
          </span>
          <span className="2xl:text-[20px] font-[400] lg:text-[15px] font-roboto text-[#1C1D1E]">
            {item.onGoingAssessment || "0"}
          </span>
        </div>
        <div className="border border-[#DCDCDC]"></div>

        <div className="flex flex-col items-center">
          <span className="text-[12px] font-[400] font-roboto text-[#7A7A7A] text-center">
            Failed Assesments
          </span>
          <span className="2xl:text-[20px] font-[400] lg:text-[15px] font-roboto text-[#1C1D1E]">
            {item.total_failed_assessments || "0"}
          </span>
        </div>
      </div>
      <div className="absolute right-1 top-1 p-2">
        <HiOutlineDotsVertical
          className="text-[20px] text-[#7A7A7A] z-40"
          onClick={handleMenu}
        />
      </div>
      {show && (
        <DropMenu
          setDeleteModal={setDeleteModal}
          setIsEditMode={setIsEditMode}
          setIsOpenModal={setIsOpenModal}
          setShowMenuIndex={setShowMenuIndex}
          NavigateToAnalyticsPage={NavigateToAnalyticsPage}
          dropdownRef={dropdownRef}
        />
      )}
      {item.status && (
        <div className="absolute top-3 left-3 w-[85px] h-[17px] rounded-[6px] px-[6px] py-[4px] flex items-center gap-2 bg-[#FFEDEE]">
          <FaStar color="#FF9900" className="text-[9px]" />
          <span className="2xl:text-[8px] lg:text-[8px] lg:font-[400] font-roboto text-[#AD2126]">
            Top Performer
          </span>
        </div>
      )}
    </div>
  );
}

function DropMenu({
  setDeleteModal,
  setIsEditMode,
  setIsOpenModal,
  setShowMenuIndex,
  NavigateToAnalyticsPage,
  dropdownRef
}) {
  return (
    <div
      className="w-[80px] rounded border border-[#DCDCDC] bg-[#F9F9FF] p-1 absolute right-[2%] top-[15%]  flex flex-col z-[8888] "
      ref={dropdownRef}
    >
      <span
        className=" font-[400] text-[13px] font-roboto text-[#1C1D1E]  hover:bg-[#f09ea073] rounded px-2 cursor-pointer"
        onClick={(e) => {
          e.stopPropagation();
          NavigateToAnalyticsPage();
        }}
      >
        Analytics
      </span>
      <span
        className=" font-[400] text-[13px] font-roboto text-[#1C1D1E]  hover:bg-[#f09ea073] rounded px-2 cursor-pointer"
        onClick={(e) => {
          e.stopPropagation();
          setDeleteModal(true);
          setShowMenuIndex(null);
        }}
      >
        Remove
      </span>
      <span
        className=" font-[400] text-[13px] font-roboto text-[#1C1D1E]  hover:bg-[#f09ea073] rounded px-2 cursor-pointer"
        onClick={(e) => {
          e.stopPropagation();
          setIsEditMode(true);
          setIsOpenModal(true);
          setShowMenuIndex(null);
        }}
      >
        Edit
      </span>
    </div>
  );
}
