import React from "react";
import { useNavigate } from "react-router-dom";

function LowGradeStudent({ item, index }) {
  const Navigate = useNavigate();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  return (
    <div
      key={index}
      style={{ boxShadow: "0px 0px 7px #e0e0e0" }}
      onClick={() => Navigate(`/students/${item.id}`)}
      className="bg-[#FCFCFC] p-4 relative rounded-[10px] cursor-pointer border border-transparent hover:border-[#017663]"
    >
      <div className="flex items-center gap-3">
        {item.profile ? (
          <img
            src={`${BASE_URL}${item?.profile}`}
            alt=""
            className="w-[50px] h-[50px] rounded-full"
          />
        ) : (
          <img
            src={"/avatar_dummy.png"}
            alt=""
            className="w-[50px] h-[50px] rounded-full"
          />
        )}

        <div>
          <h1 className="2xl:font-[600] 2xl:text-[16px] lg:font-[500] lg:text-[14px]  font-roboto">
            {item.student_name || item.name}
          </h1>
          <p className="2xl:font-[400] 2xl:text-[12px] lg:font-[300] lg:text-[11px]  font-roboto text-[#7A7A7A]">
            {item.student_id}
          </p>
        </div>
      </div>
      <p
        className={`text-[15px] font-[600] font-roboto absolute top-2 right-2 ${
          item.ataindance < 78
            ? "text-[#AD2126]"
            : item.ataindance < 90
            ? "text-[#FFC121]"
            : "text-[#017663]"
        }`}
      >
        {item.ataindance}%
      </p>

      <h1 className="2xl:font-[600] 2xl:text-[18px] lg:font-[500] lg:text-[16px]  font-roboto text-[#2D2D2D] mt-4">
        Assessment:
      </h1>
      <div className="flex justify-between mt-1">
        <div className="w-[33.33%] flex flex-col items-center justify-center">
          <p className="text-[#7A7A7A]">Pending</p>
          <p className="2xl:font-[500] 2xl:text-[18px] lg:font-[500] lg:text-[16px]  font-roboto text-[#2D2D2D]">
            {item.total_pending_assessments}
          </p>
        </div>
        <div className="w-[33.33%] flex flex-col items-center justify-center">
          <p className="text-[#7A7A7A]">Passed</p>
          <p className="2xl:font-[500] 2xl:text-[18px] lg:font-[500] lg:text-[16px]  font-roboto text-[#2D2D2D]">
            {item.total_passed_assessments}
          </p>
        </div>
        <div className="w-[33.33%] flex flex-col items-center justify-center">
          <p className="text-[#7A7A7A]">Failed</p>
          <p className="2xl:font-[500] 2xl:text-[18px] lg:font-[500] lg:text-[16px]  font-roboto text-[#2D2D2D]">
            {item.total_failed_assessments}
          </p>
        </div>
      </div>
    </div>
  );
}

export default LowGradeStudent;
