import axios from "axios";
import { useContext, useState } from "react";
import { getAccessToken } from "../storage/storage";
import GlobalContext from "../context/GlobalContext";

const useGetLearningCenter = () => {
  const token = getAccessToken();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [getLearningCenter, setGetLearningCenter] = useState(null);
  const [learningCenterLoading, setLearningCenterLoading] = useState(true);
  const [learningCenterMessage, setLearningCenterMessage] = useState(null);
  const { setUpdateResponse } = useContext(GlobalContext);

  const GetLearningCenterList = async () => {
    setLearningCenterMessage(null);
    await axios
      .get(`${BASE_URL}/api/learning-centers/`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((response) => {
        setLearningCenterLoading(false);
        setUpdateResponse(false);
        setGetLearningCenter(response.data);
        if (response.data.length === 0) {
          setLearningCenterMessage("No classes found");
        } else {
          setLearningCenterMessage(null);
        }
      })
      .catch((error) => {
        setLearningCenterLoading(false);
        setLearningCenterMessage("Oops! Something went wrong");
      });
  };
  return {
    GetLearningCenterList,
    learningCenterLoading,
    getLearningCenter,
    learningCenterMessage
  };
};
export default useGetLearningCenter;
