/* eslint-disable react/jsx-pascal-case */
import React, { Fragment, useRef } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { Dialog, Transition } from "@headlessui/react";

function ViewAssessmentDetails({ isOpenModal, setIsOpenModal, review }) {
  const cancelButtonRef = useRef(null);

  const handleClose = () => {
    setIsOpenModal(false);
  };

  return (
    <Transition.Root show={isOpenModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={handleClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center sm:p-4 xs:p-1 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-[#fff]  text-left shadow-xl transition-all 2xl:w-[500px] lg:w-[500px] xs:w-full h-auto xs:mx-2 md:p-6 xs:p-3">
                <div className="absolute top-[10px] right-[10px] cursor-pointer">
                  <AiOutlineClose onClick={handleClose} />
                </div>
                <div className="flex flex-col">
                  <h1 className="2xl:text-[28px] font-[600] lg:text-[24px] font-roboto">
                    Assessment Review
                  </h1>
                  <div className="mt-2">
                    <h1 className=" font-[600] text-[16px] font-roboto">
                      Skill to Review
                    </h1>
                    <p className=" font-[400] text-[14px] text-gray-500 ">
                      {review?.skill_to_review
                        ? review?.skill_to_review
                        : "Not found"}
                    </p>
                    <h1 className=" font-[600] text-[16px] font-roboto mt-4">
                      Skill to Teach
                    </h1>
                    <p className=" font-[400] text-[14px] text-gray-500">
                      {review?.skill_to_teach
                        ? review?.skill_to_teach
                        : "Not found"}
                    </p>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default ViewAssessmentDetails;
