/* eslint-disable react/jsx-pascal-case */
import React, { useCallback, useContext, useEffect, useState } from "react";
import "../styles/switch.css";
import DashboardLayout from "../components/shared/DashboardLayout";
import { BiExport } from "react-icons/bi";
import { PiStarThin, PiStudent } from "react-icons/pi";
import BarGraph from "../components/graphs/BarGraph";
import { MdOutlineAssignment } from "react-icons/md";
import { FaArrowTrendUp } from "react-icons/fa6";
import LineGraph from "../components/graphs/LineGraph";
import useGetClasses from "../hooks/useGetClasses";
import useGetGradesList from "../hooks/useGetGradesList";
import axios from "axios";
import ShowMessage from "../components/shared/ShowMessage";
import AppHead from "../seo/AppHead";
import { ButtonLoader_2 } from "../components/shared/Loaders";
import DeleteModal from "../components/modals/DeleteModal";
import GlobalContext from "../context/GlobalContext";
import Swal from "sweetalert2";
import { getAccessToken } from "../storage/storage";
import { debounce } from "lodash";
import { CiSearch } from "react-icons/ci";
import * as XLSX from "xlsx";

const table_headers = [
  {
    // A
    category: "Uppercase",
    name: "Letter name",
    assessment_title: "A - Letter Names - Uppercase",
    score: "26",
    grades: ["0", "1", "2", "3"]
  },
  {
    // B
    category: "Lowercase",
    name: "Letter name",
    assessment_title: "B - Letter Names - Lowercase",
    score: "26",
    grades: ["0", "1", "2", "3"]
  },
  {
    // C
    category: "Consonant",
    name: "Sounds",
    assessment_title: "C - Consonant",
    score: "21",
    grades: ["0", "1", "2", "3"]
  },
  {
    // D
    category: "",
    name: "Long Vowel - Short Vowel",
    assessment_title: "D - Long Vowel  - Short Vowel",
    score: "10",
    grades: ["0", "1", "2", "3"]
  },
  {
    // E
    category: "CVC",
    name: "Short Vowel",
    assessment_title: "E - Short Vowels in CVC Words",
    score: "15",
    grades: ["0", "1", "2", "3"]
  },
  {
    // F
    category: "Consonant blends",
    name: "Short Vowel",
    assessment_title: "F - Short Vowels and Consonant Blends",
    score: "15",
    grades: ["1", "2", "3"]
  },
  {
    // G
    category: "digraphs, -tch",
    name: "Short Vowel",
    assessment_title: "G - Short Vowels, Digraphs, and –tch Trigraph",
    score: "15",
    grades: ["1", "2", "3"]
  },
  {
    // H
    category: "R-controlled",
    name: "Vowel",
    assessment_title: "H - R–Controlled Vowels",
    score: "15",
    grades: ["1", "2", "3"]
  },
  {
    // I
    category: "Spelling",
    name: "Long Vowel",
    assessment_title: "I - Long Vowels Spellings",
    score: "15",
    grades: ["1", "2", "3"]
  },
  {
    // J
    category: "Variant",
    name: "Vowel",
    assessment_title: "J - Variant Vowels",
    score: "15",
    grades: ["1", "2", "3"]
  },
  {
    // K
    category: "Low frequency vowel,",
    name: "consonant spellings",
    assessment_title: "K - Low Frequency Vowel and Consonant Spellings",
    score: "15",
    grades: ["1", "2", "3"]
  },
  {
    // L
    category: "Multisyllabic,",
    name: "words",
    assessment_title: "L - Multisyllabic words",
    score: "24",
    grades: ["1", "2", "3"]
  }
  // { category: "", name: "", score: "-", grades: ["0", "1", "2"] },
];

function ClassAnalyticsPage() {
  return (
    <>
      <AppHead title="Class analytics - ELEMENT EDUCATION" />
      <DashboardLayout>
        <div className="bg-backgroundColor px-4 py-2 flex flex-col w-full h-auto">
          <MainLayout />
        </div>
      </DashboardLayout>
    </>
  );
}

export default ClassAnalyticsPage;

function MainLayout() {
  const token = getAccessToken();
  const { updateResponse, setUpdateResponse } = useContext(GlobalContext);
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedClassName, setSelectedClassName] = useState("");
  const [selectedGrade, setSelectedGrade] = useState("");
  const [selectedGradeId, setSelectedGradeId] = useState("");
  const [classCounts, setClassCounts] = useState(null);
  const [loading, setLoading] = useState(false);
  const [gradeData, setGradeData] = useState(null);
  const [query, setQuery] = useState("");

  const { classessData, GetClasses } = useGetClasses();
  useEffect(() => {
    GetClasses(``);
  }, []);

  const { getGradesList, gradesList } = useGetGradesList();
  useEffect(() => {
    if (selectedClass) {
      getGradesList(`?class_id=${selectedClass}`);
    }
  }, [selectedClass]);

  const getClassAnalyticsCount = async (filter) => {
    await axios
      .get(`${BASE_URL}/api/class-analytics${filter}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((response) => {
        setClassCounts(response.data);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    if (selectedClass) {
      getClassAnalyticsCount(`?class_id=${selectedClass}`);
    }
  }, [selectedClass]);

  const getGradeAnalytics = async (filter) => {
    await axios
      .get(`${BASE_URL}/api/grade-analysis-results${filter}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((response) => {
        setGradeData(response.data);
        setUpdateResponse(false);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (selectedGradeId) {
      getGradeAnalytics(
        `?class_id=${selectedClass}&grade=${selectedGradeId}&search=${query}`
      );
    }
  }, [selectedGradeId, updateResponse]);

  const debouncedSearch = useCallback(
    debounce((selectedClass, selectedGradeId, value) => {
      getGradeAnalytics(
        `?class_id=${selectedClass}&grade=${selectedGradeId}&search=${value}`
      );
    }, 1000),
    []
  );

  const handleChange = (e) => {
    const { value } = e.target;
    setQuery(value);
    debouncedSearch(selectedClass, selectedGradeId, value);
  };

  useEffect(() => {
    if (selectedClass === "") {
      setSelectedGrade("");
      setSelectedGradeId("");
    }
  }, [selectedClass]);

  return (
    <>
      {/* Class Analytics */}
      <div className="flex items-center justify-between w-full">
        <h1 className="2xl:font-[700] 2xl:text-[36px] lg:font-[500] lg:text-[30px] font-roboto text-[#263238]">
          Class Analytics
        </h1>
        <div className="flex items-center gap-3">
          <div className="custom-select w-[150px] h-[50px]">
            <select
              onChange={(e) => {
                const value = e.target.value.split("|");
                setSelectedClass(value[0]);
                setSelectedClassName(value[1]);
                setSelectedGrade("");
                setSelectedGradeId("");
                setGradeData(null);
              }}
              defaultValue=""
              value={
                selectedClass && selectedClassName
                  ? `${selectedClass}|${selectedClassName}`
                  : ""
              }
            >
              <option value="" disabled>
                Select Class
              </option>
              {classessData?.map((data, index) => (
                <option key={index} value={`${data.id}|${data.class_name}`}>
                  {data?.class_name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 gap-4 mt-3 ">
        <CountCard
          icon={<PiStudent />}
          title={"Grades"}
          count={classCounts?.total_grades || 0}
          bgColor={"bg-[#ff8e4344]"}
          textColor={"text-[#FF8F43]"}
        />
        <CountCard
          icon={<PiStudent />}
          title={"Total Students"}
          count={classCounts?.total_students || 0}
          bgColor={"bg-[#01766342]"}
          textColor={"text-[#017663]"}
        />
        <CountCard
          icon={<MdOutlineAssignment />}
          title={"Total Assessments"}
          count={classCounts?.total_assessments || 0}
          bgColor={"bg-[#ad21264d]"}
          textColor={"text-[#AD2126]"}
        />
      </div>
      {/* Grade Analytics */}
      <div className="flex items-center justify-between w-full mt-6">
        <h1 className="2xl:font-[700] 2xl:text-[36px] lg:font-[500] lg:text-[30px] font-roboto text-[#263238]">
          {selectedGrade ? <>Grade: {selectedGrade}</> : <>Grade</>}
        </h1>
        <div className="custom-select w-[150px] h-[50px]">
          <select
            onChange={(e) => {
              const value = e.target.value.split("|");
              setSelectedGrade(value[0]);
              setSelectedGradeId(value[1]);
              setGradeData(null);
              setLoading(true);
            }}
            value={
              selectedGrade && selectedGradeId
                ? `${selectedGrade}|${selectedGradeId}`
                : ""
            }
          >
            <option value="" disabled selected>
              Select Grade
            </option>
            {gradesList?.map((data, index) => (
              <option key={index} value={`${data.grade}|${data.grade_id}`}>
                Grade: {data.grade}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 gap-4 mt-3">
        <CountCard
          icon={<PiStarThin />}
          title={"Top Performer"}
          count={gradeData?.top_performers || "0"}
          bgColor={"bg-[#ffc02150]"}
          textColor={"text-[#FFC121]"}
        />
        <CountCard
          icon={<FaArrowTrendUp />}
          title={"Average"}
          count={gradeData?.average_students || "0"}
          bgColor={"bg-[#26265f57]"}
          textColor={"text-[#26265F]"}
        />
        <CountCard
          icon={<PiStudent />}
          title={"Low Grade"}
          count={gradeData?.low_grade_students || "0"}
          bgColor={"bg-[#ad21264d]"}
          textColor={"text-[#AD2126]"}
        />
      </div>
      <div className="w-full h-auto mt-5">
        <ClassAnalyticesTable
          selectedClassName={selectedClassName}
          selectedGrade={selectedGrade}
          gradeData={gradeData}
          loading={loading}
          query={query}
          handleChange={handleChange}
        />
      </div>

      {selectedGrade && (
        <div className="w-full h-auto mt-5">
          <TableGraph
            selectedClass={selectedClass}
            selectedGradeId={selectedGradeId}
            graphData={gradeData?.assessment_categories}
          />
        </div>
      )}
      <div className="w-full h-auto mt-5">
        <StudentComparsion gradeData={gradeData} />
      </div>
    </>
  );
}

function CountCard({ icon, title, count, bgColor, textColor }) {
  return (
    <>
      <div
        className="bg-white rounded-[20px] w-full py-6 px-6 flex flex-col justify-center items-center"
        style={{ boxShadow: "0px 0px 7px #e0e0e0" }}
      >
        <div
          className={`text-[25px] ${bgColor} ${textColor} rounded-[8px] p-2`}
        >
          {icon}
        </div>
        <p className="2xl:font-[400] 2xl:text-[22px] lg:font-[300] lg:text-[20px]  font-roboto text-[#737373] mt-2">
          {title}
        </p>
        <h1 className="2xl:font-[700] 2xl:text-[40px] lg:font-[500] lg:text-[37px] font-roboto text-[#263238] mt-2">
          {count}
        </h1>
      </div>
    </>
  );
}

function ClassAnalyticesTable(props) {
  const {
    selectedClassName,
    selectedGrade,
    gradeData,
    loading,
    query,
    handleChange
  } = props;
  const token = getAccessToken();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const { setUpdateResponse } = useContext(GlobalContext);
  const [showPercentage, setShowPercentage] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isEditModeIndex, setIsEditModeIndex] = useState(null);
  const [inputValues, setInputValues] = useState({});

  const filteredHeaders = table_headers.filter((header) =>
    header.grades.includes(selectedGrade)
  );

  function CalculatePercentage(total, obtain) {
    if (total && obtain) {
      return Math.round((obtain / total) * 100);
    }
  }

  const handleInputChange = (studentId, assessmentId, key, value) => {
    setInputValues((prevValues) => ({
      ...prevValues,
      [studentId]: {
        ...prevValues[studentId],
        [assessmentId]: {
          ...prevValues[studentId]?.[assessmentId],
          [key]: value
        }
      }
    }));
  };

  const handleSave = async () => {
    let updatedStudent = null;

    for (const student of gradeData.grade_data) {
      const updatedAssessments = student.assessments.map((assessment) => {
        const inputValue =
          inputValues[student.student_id]?.[assessment.assessment_name]?.score;
        if (inputValue !== undefined && inputValue !== assessment.score) {
          return {
            ...assessment,
            score: parseInt(inputValue, 10)
          };
        }
        return assessment;
      });

      const hasChanged = updatedAssessments.some(
        (assessment, index) =>
          assessment.score !== student.assessments[index].score
      );

      if (hasChanged) {
        updatedStudent = {
          ...student,
          assessments: updatedAssessments
        };
        break;
      }
    }

    if (updatedStudent) {
      await axios
        .put(`${BASE_URL}/api/update-assessment-record/`, updatedStudent, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })

        .then((response) => {
          setIsEditModeIndex(null);
          setSelectedRow(null);
          setInputValues({});
          setUpdateResponse(true);
          Swal.fire({
            icon: "success",
            text: "Record successfully updated!",
            customClass: {
              confirmButton: "custom-green-button"
            }
          });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            text:
              error?.response?.data?.message || "Oops! Something went wrong",
            customClass: {
              confirmButton: "custom-red-button"
            }
          });
        });
    } else {
      setIsEditModeIndex(null);
      setSelectedRow(null);
      setInputValues({});
    }
  };

  const exportToExcel = () => {
    const first_header = filteredHeaders?.map((item) => item.category);
    const second_header = filteredHeaders?.map((item) => item.name);
    const third_header = filteredHeaders?.map((item) =>
      showPercentage ? "100%" : item.score
    );

    first_header.unshift("");
    second_header.unshift("Student name");
    third_header.unshift("Maximum");

    const headers = [first_header, second_header, third_header];

    const data = gradeData?.grade_data?.map((item) => {
      return [
        item.student_name,
        ...filteredHeaders.map((header, index) => {
          const assessment = item.assessments[index];
          if (assessment) {
            return showPercentage
              ? `${CalculatePercentage(header.score, assessment.score || 0)}%`
              : assessment.score || "-";
          }
          return "-";
        })
      ];
    });

    const finalData = [...headers, ...data];
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(finalData);
    // Calculate max length of data in each column to set column width
    const colWidths = second_header.map((header, index) => {
      const maxLength = Math.max(
        header.length,
        ...data.map((row) => (row[index] ? row[index].toString().length : 0))
      );
      return { width: maxLength + 2 };
    });
    // Apply column widths to the worksheet
    ws["!cols"] = colWidths;
    XLSX.utils.book_append_sheet(wb, ws, "Grades");
    XLSX.writeFile(wb, `${selectedClassName}.xlsx`);
  };

  return (
    <>
      {!selectedGrade && (
        <div className="w-full h-[400px] bg-white border border-[#DCDCDC] rounded-[20px] flex justify-center items-center">
          <ShowMessage message="Select class & grade to show class results" />
        </div>
      )}
      {selectedGrade && gradeData?.grade_data?.length === 0 && (
        <div className="w-full h-[400px] bg-white border border-[#DCDCDC] rounded-[20px] flex justify-center items-center">
          <ShowMessage
            message={`Data not found against Grade: ${selectedGrade}`}
          />
        </div>
      )}
      {loading && (
        <div className="w-full h-[400px] bg-white border border-[#DCDCDC] rounded-[20px] flex justify-center items-center">
          <ButtonLoader_2 />
        </div>
      )}
      {gradeData?.grade_data?.length > 0 && selectedGrade && (
        <div className="w-full flex items-center justify-between mb-2">
          <div className="flex items-center border bg-white border-[#DCDCDC] hover:border-[#017663] focus-within:border-[#017663] rounded-[10px] p-2 w-[320px] h-[50px]">
            <input
              type="search"
              value={query}
              onChange={handleChange}
              placeholder="Search..."
              className="outline-none border-none w-[100%] h-full bg-transparent px-3"
            />
            <CiSearch className="text-[20px] text-[#C1C1C1]" />
          </div>
          <button
            className="h-[50px] flex items-center border bg-[#017663] border-[#017663] rounded w-[100px] justify-center gap-2 cursor-pointer text-white"
            onClick={() => exportToExcel()}
          >
            <BiExport className="text-[18px]" />
            <p className="2xl:font-[500] 2xl:text-[16px] lg:font-[300] lg:text-[14px] font-roboto">
              Export
            </p>
          </button>
        </div>
      )}
      {gradeData?.grade_data?.length > 0 && selectedGrade && (
        <div className="w-full h-auto overflow-y-auto bg-white border border-[#DCDCDC] rounded-[20px]">
          <div className="flex justify-between items-center px-5 py-5">
            <div className="flex gap-5 items-center">
              {[
                { bg_color: "bg-[#017663]", name: "Benchmark" },
                { bg_color: "bg-[#FFC121]", name: "Strategic" },
                { bg_color: "bg-[#AD2126]", name: "Intensive" }
              ].map((item, index) => (
                <div className="flex gap-2 items-center" key={index}>
                  <div className={`w-[12px] h-[12px] ${item.bg_color}`} />
                  <p className="text-[#7A7A7A] text-[12px] font-[400] font-roboto">
                    {item.name}
                  </p>
                </div>
              ))}
            </div>
            <div className="flex gap-5 items-center">
              <h1 className="text-[#1C1D1E] text-[15px] font-[600] font-roboto">
                Show percentage (%)
              </h1>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={showPercentage}
                  onChange={() => setShowPercentage(!showPercentage)}
                />
                <span className="slider round"></span>
              </label>
            </div>
          </div>
          <div className="w-full min-h-auto max-h-[500px] mt-3 border border-[#DCDCDC] rounded-[20px] overflow-y-scroll overflow-x-scroll">
            <table className="w-full text-sm text-left rtl:text-right">
              <thead className="sticky z-[1] top-0 bg-white">
                <tr className="w-full">
                  <th scope="col" className="text-center min-w-[120px]">
                    <div className="flex flex-col items-center justify-end w-auto h-[60px] pb-2 ">
                      <p className="text-[#1C1D1E] font-[400] font-roboto text-[10px]"></p>
                      <h1 className="text-[#1C1D1E] font-[600] font-roboto text-[12px]">
                        Student name
                      </h1>
                    </div>
                    <div className="bg-[#F0F0F5] text-[#1C1D1E] font-[600] font-roboto text-[14px] py-2 flex justify-center">
                      <p className="text-center">Maximum</p>
                    </div>
                  </th>
                  {filteredHeaders?.map((item, index) => (
                    <th
                      scope="col"
                      className="text-center min-w-[120px]"
                      key={index}
                    >
                      <div className="flex flex-col items-center justify-end w-auto h-[60px] pb-2">
                        <p className="text-[#1C1D1E] font-[400] font-roboto text-[10px]">
                          {item.category}
                        </p>
                        <h1 className="text-[#1C1D1E] font-[600] font-roboto text-[12px]">
                          {item.name}
                        </h1>
                      </div>
                      <div className="bg-[#F0F0F5] text-[#1C1D1E] font-[600] font-roboto text-[14px] py-2 flex justify-center">
                        <p className="text-center">
                          {showPercentage ? "100%" : item.score}
                        </p>
                      </div>
                    </th>
                  ))}
                  {/* <th scope="col" className="text-center min-w-[120px]">
                    <div className="flex flex-col items-center justify-end w-auto h-[60px] pb-2">
                      <p className="text-[#1C1D1E] font-[400] font-roboto text-[10px]"></p>
                      <h1 className="text-[#1C1D1E] font-[600] font-roboto text-[12px]"></h1>
                    </div>
                    <div className="bg-[#F0F0F5] text-[#1C1D1E] font-[600] font-roboto text-[14px] py-2 flex justify-center">
                      <p className="text-center">Action</p>
                    </div>
                  </th> */}
                </tr>
              </thead>
              <tbody>
                {gradeData?.grade_data?.map((item, index) => (
                  <tr key={index} className="bg-white hover:bg-[#ffedee]">
                    <td className="py-3 text-[#7A7A7A] text-center">
                      {item.student_name}
                    </td>
                    {filteredHeaders.map((header, headerIndex) => {
                      const matchingAssessment = item.assessments.find(
                        (assessment) =>
                          assessment.assessment_name === header.assessment_title
                      );
                      const total_score =
                        matchingAssessment?.total_assessment_score;
                      const score = matchingAssessment
                        ? matchingAssessment.score
                        : "-";
                      return (
                        <td
                          key={headerIndex}
                          className={`py-3 text-center font-[600] ${
                            CalculatePercentage(total_score, score) < 78
                            // ? "text-[#AD2126]"
                            // : CalculatePercentage(total_score, score) < 90
                            // ? "text-[#FFC121]"
                            // : "text-[#017663]"
                          }`}
                        >
                          {showPercentage ? (
                            <>
                              {CalculatePercentage(total_score, score)
                                ? `${CalculatePercentage(total_score, score)}%`
                                : "-"}
                            </>
                          ) : (
                            <>
                              {isEditModeIndex === index ? (
                                <input
                                  type="text"
                                  className="border border-[#7A7A7A] px-1 py-1 rounded-md w-[60%]"
                                  value={
                                    inputValues[item.student_id]?.[
                                      matchingAssessment?.assessment_name
                                    ]?.score === undefined
                                      ? score
                                      : inputValues[item.student_id]?.[
                                          matchingAssessment?.assessment_name
                                        ]?.score || ""
                                  }
                                  onChange={(e) =>
                                    handleInputChange(
                                      item.student_id,
                                      matchingAssessment?.assessment_name,
                                      "score",
                                      e.target.value
                                    )
                                  }
                                />
                              ) : (
                                score
                              )}
                            </>
                          )}
                        </td>
                      );
                    })}
                    {/* <td className="py-3 text-[#7A7A7A] text-center">
                      {isEditModeIndex === index ? (
                        <div className="flex gap-2 justify-center items-center">
                          <MdOutlineCancel
                            className="cursor-pointer text-[18px] hover:text-red-500"
                            onClick={() => {
                              setIsEditModeIndex(null);
                              setSelectedRow(null);
                              setInputValues({});
                            }}
                          />
                          <MdSaveAs
                            className="cursor-pointer text-[18px] hover:text-green-500"
                            onClick={handleSave}
                          />
                        </div>
                      ) : (
                        <div className="flex gap-2 justify-center items-center">
                          {!showPercentage && (
                            <FaEdit
                              className="cursor-pointer text-[18px] hover:text-green-500"
                              onClick={() => {
                                setIsEditModeIndex(index);
                                setSelectedRow(item);
                              }}
                            />
                          )}
                          <MdDelete
                            className="cursor-pointer text-[18px] hover:text-red-500"
                            onClick={() => {
                              setDeleteModal(true);
                              setSelectedRow(item);
                            }}
                          />
                        </div>
                      )}
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
      <DeleteModal
        isOpenModal={deleteModal}
        setIsOpenModal={setDeleteModal}
        setSelected={setSelectedRow}
        url={`/api/delete-assessment-record?assessment_id=${selectedRow?.assessment_id}&student_id=${selectedRow?.student_id}`}
      />
    </>
  );
}

function TableGraph({ selectedClass, selectedGradeId, graphData }) {
  const token = getAccessToken();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [loading, setLoading] = useState(false);
  const [gradeData, setGradeData] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState("All");

  const getGradeAnalytics = async (filter) => {
    await axios
      .get(`${BASE_URL}/api/class-grade-analytics${filter}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((response) => {
        setGradeData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (selectedGradeId) {
      getGradeAnalytics(`?class_id=${selectedClass}&grade=${selectedGradeId}`);
    }
  }, [selectedGradeId]);
  return (
    <>
      <div className="w-full h-auto bg-white border border-[#DCDCDC] rounded-[20px] py-5">
        {/* Letter names and sounds breakdown */}

        <div className="w-full h-auto flex gap-4 justify-between px-5">
          <div className="w-[50%] h-auto">
            <h1 className="text-[#1C1D1E] text-[22px] font-[700] font-roboto">
              Letter names and sounds breakdown
            </h1>
            <div className="w-full min-h-[300px] mt-3 border border-[#DCDCDC]">
              <table className="w-full text-sm text-left rtl:text-right">
                <thead className="sticky z-[1] top-0 text-[#1C1D1E] bg-[#F0F0F5] rounded-[20px] font-semibold text-[16px]">
                  <tr className="w-full">
                    {[
                      "Letter name/sound components",
                      "Class Average",
                      "Points Available"
                    ].map((item, index) => (
                      <th
                        scope="col"
                        className="py-3 px-3 text-center"
                        key={index}
                      >
                        {item}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {gradeData?.first_assessment?.map((item, index) => (
                    <tr key={index} className="bg-white">
                      <td className="py-3 px-3 text-[#737373]">
                        {item.assessment_name}
                      </td>
                      <td className="py-3 px-3 text-[#1C1D1E] font-[600] text-[16px] text-center">
                        {item.average_score}
                      </td>
                      <td className="py-3 px-3 text-[#1C1D1E]  font-[600] text-[16px] text-center">
                        {item.points}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {gradeData?.first_assessment?.length === 0 && !loading && (
                <div className="w-full h-[300px] flex justify-center items-center">
                  <ShowMessage message="Data not found" />
                </div>
              )}
            </div>
          </div>
          <div className="w-[50%] h-auto">
            <h1 className="text-[#1C1D1E] text-[22px] font-[700] font-roboto">
              Reading and Decoding
            </h1>
            <div className="w-full min-h-[300px] mt-3 border border-[#DCDCDC]">
              <table className="w-full text-sm text-left rtl:text-right">
                <thead className="sticky z-[1] top-0 text-[#1C1D1E] bg-[#F0F0F5] rounded-[20px] font-semibold text-[16px]">
                  <tr className="w-full">
                    {[
                      "Reading and Decoding",
                      "Class Average",
                      "Points Available"
                    ].map((item, index) => (
                      <th
                        scope="col"
                        className="py-3 px-3  text-center"
                        key={index}
                      >
                        {item}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {gradeData?.other_assessment?.map((item, index) => (
                    <tr key={index} className="bg-white">
                      <td className="py-3 px-3 text-[#737373] ">
                        {item.assessment_name}
                      </td>
                      <td className="py-3 px-3 text-[#1C1D1E] font-[600] text-[16px] text-center">
                        {item.average_score}
                      </td>
                      <td className="py-3 px-3 text-[#1C1D1E]  font-[600] text-[16px] text-center">
                        {item.points}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {gradeData?.other_assessment?.length === 0 && !loading && (
                <div className="w-full h-[300px] flex justify-center items-center">
                  <ShowMessage message="Data not found" />
                </div>
              )}
            </div>
          </div>
        </div>
        {/* {graphData?.length > 0 && (
          <>
            <div className="w-full border border-[#DCDCDC] my-10" />
         
            <div className="w-full flex items-center justify-end px-5">
              <div className="custom-select w-[150px] h-[50px]">
                <select onChange={(e) => setSelectedFilter(e.target.value)}>
                  <option value="All" selected>
                    All
                  </option>
                  <option value="Benchmark">Benchmark</option>
                  <option value="Strategic">Strategic</option>
                  <option value="Intensive">Intensive</option>
                </select>
              </div>
            </div>
            <div className="w-full flex justify-center items-center mt-5 px-5">
              <BarGraph graphData={graphData} selectedFilter={selectedFilter} />
            </div>
          </>
        )} */}
      </div>
    </>
  );
}

function StudentComparsion({ gradeData }) {
  return (
    <>
      {gradeData?.grade_data?.length > 0 && (
        <>
          <h1 className="text-[#1C1D1E] text-[22px] font-[700] font-roboto mb-2">
            Student Comparison Visuals
          </h1>
          <div className="w-full h-auto bg-white border border-[#DCDCDC] rounded-[20px] py-5 px-5">
            <LineGraph gradeData={gradeData} />
          </div>
        </>
      )}
    </>
  );
}
