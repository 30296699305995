/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/jsx-pascal-case */
import React, { Fragment, useRef, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { Dialog, Transition } from "@headlessui/react";
import { BsTextareaT } from "react-icons/bs";
import hand from "../../assets/hand.png";
import {
  MdClose,
  MdKeyboardVoice,
  MdOutlineDateRange,
  MdOutlineGrade
} from "react-icons/md";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import useAssessmentNextPageDetail from "../../hooks/useAssessmentNextPageDetail";
import AudioRecorder from "./AudioRecorder";
import StudentsDropdown from "../StudentsDropdown";
import { PiUsersThree } from "react-icons/pi";
import { LuSchool2 } from "react-icons/lu";

function AttemptAssessmentModal(props) {
  const cancelButtonRef = useRef(null);

  const handleClose = () => {
    props.setIsOpenModal(false);
  };

  return (
    <Transition.Root show={props.isOpenModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={handleClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center sm:p-4 xs:p-1 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-[#fff]  text-left shadow-xl transition-all 2xl:w-[1100px] lg:w-[900px] xs:w-full min-h-[660px] xs:mx-2 md:p-6 xs:p-3">
                <div className="absolute top-[10px] right-[10px] cursor-pointer">
                  <AiOutlineClose onClick={handleClose} />
                </div>
                <h1 className="2xl:font-[700] 2xl:text-[36px] lg:font-[600] lg:text-[32px] font-roboto">
                  Attempt Assessment
                </h1>
                <AssessmentForm handleClose={handleClose} data={props.data} />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default AttemptAssessmentModal;

function AssessmentForm({ data, handleClose }) {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedLetter, setSelectedLetter] = useState(null);
  const [sectionID, setSectionID] = useState(0);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [skillToReview, setSkillToReview] = useState("");
  const [skillToTeach, setSkillToTeach] = useState("");

  const {
    nextPageLoading,
    assessmentNextpage,
    setNextPageLoading,
    setNextPageData,
    nextPageData,
    setCorrectCounts,
    setAnswers,
    answers,
    correctCounts,
    activeIndex,
    setActiveIndex
  } = useAssessmentNextPageDetail();

  function isIndexInArray(arr, index) {
    return index >= 0 && index < arr.length;
  }

  const handleTiTickClick = (item, index) => {
    if (isIndexInArray(answers, index)) {
      const newAnswers = [...answers];
      newAnswers[index] = item;
      setAnswers(newAnswers);
      if (answers[index] !== item) {
        setCorrectCounts(correctCounts + 1);
      }
    } else {
      setAnswers((previousData) => [...previousData, item]);
      setActiveIndex(activeIndex + 1);
      setCorrectCounts(correctCounts + 1);
    }
  };

  const handleMdCloseClick = (item, index) => {
    if (isIndexInArray(answers, index)) {
      const newAnswers = [...answers];
      newAnswers[index] = " ";
      setAnswers(newAnswers);
      if (answers[index] !== " ") {
        setCorrectCounts(correctCounts - 1);
      }
    } else {
      const wrong = " ";
      setAnswers((previousData) => [...previousData, wrong]);
      setActiveIndex(activeIndex + 1);
    }
  };

  const handleClearData = () => {
    setAnswers([]);
    setCorrectCounts(0);
    setActiveIndex(0);
  };

  const submitAndNext = (sectionId, status) => {
    if (nextPageData?.is_attempted) {
      assessmentNextpage(
        {
          assessment_id: data?.id,
          student_id: selectedStudent?.student_id,
          section_id: sectionId
        },
        sectionId,
        handleClearData,
        setSectionID,
        handleClose
      );
    } else {
      assessmentNextpage(
        {
          assessment_id: data?.id,
          assessment_data_name: nextPageData?.assessment_data?.name,
          student_id: selectedStudent?.student_id,
          section_id: sectionId,
          status: status,
          correct_characters: answers,
          total_correct: correctCounts,
          total_score: nextPageData?.assessment_data?.counts?.length,
          skill_to_review: skillToReview,
          skill_to_teach: skillToTeach
        },

        sectionId,
        handleClearData,
        setSectionID,
        handleClose,
        setSkillToReview(""),
        setSkillToTeach("")
      );
    }
  };

  const submitAndPrevious = (sectionId) => {
    assessmentNextpage(
      {
        assessment_id: data?.id,
        student_id: selectedStudent?.student_id,
        section_id: sectionId
      },
      sectionId,
      handleClearData,
      setSectionID,
      handleClose
    );
  };

  const handleSelectChange = (item) => {
    setSelectedStudent(item);
    setAnswers([]);
    setCorrectCounts(0);
    setActiveIndex(0);
    setSectionID(0);
    setNextPageLoading(true);
    setNextPageData(null);
    assessmentNextpage({
      assessment_id: data?.id,
      student_id: item.student_id,
      section_id: 0
    });
  };

  const isLastPage = nextPageData?.assessment_length === sectionID + 1;

  return (
    <>
      {/* Top Bar */}
      <div className="w-[100%] py-4 px-6 min-h-[110px] rounded-[10px] mt-2 flex items-center text-[#1C1D1E] bg-[#F0F0F5]">
        <div className="flex gap-2 w-[20%]">
          <BsTextareaT className="text-[20px] mt-1" />
          <div className="w-full">
            <h1 className="2xl:font-[700] lg:font-[500] 2xl:text-[20px] lg:text-[18px] font-roboto">
              Title
            </h1>
            <div className="w-full flex h-[35px]">
              <p>{data.assessment_name}</p>
            </div>
          </div>
        </div>

        <div className="flex gap-2 w-[20%]">
          <MdOutlineDateRange className="text-[20px] mt-1" />
          <div className="w-full">
            <h1 className="2xl:font-[700] lg:font-[500] 2xl:text-[20px] lg:text-[18px] font-roboto">
              Due Date
            </h1>
            <div className="w-full flex h-[35px]">
              <p>{data.due_date}</p>
            </div>
          </div>
        </div>

        <div className="flex gap-2 w-[15%]">
          <LuSchool2 className="text-[20px] mt-1" />
          <div className="w-full">
            <h1 className="2xl:font-[700] lg:font-[500] 2xl:text-[20px] lg:text-[18px] font-roboto">
              Class
            </h1>
            <div className="w-full flex h-[35px]">
              <p>{data.Class}</p>
            </div>
          </div>
        </div>

        <div className="flex gap-2 w-[15%]">
          <MdOutlineGrade className="text-[20px] mt-1" />
          <div className="w-full">
            <h1 className="2xl:font-[700] lg:font-[500] 2xl:text-[20px] lg:text-[18px] font-roboto">
              Grade
            </h1>
            <div className="w-full flex h-[35px]">
              <p>{data.grade}</p>
            </div>
          </div>
        </div>

        <div className="flex gap-2 w-[30%]">
          <PiUsersThree className="text-[20px] mt-1" />
          <div className="w-full">
            <h1 className="2xl:font-[700] lg:font-[500] 2xl:text-[20px] lg:text-[18px] font-roboto">
              Students
            </h1>
            <StudentsDropdown
              selectedStudent={selectedStudent}
              handleSelectChange={handleSelectChange}
              assessmentId={data?.id}
            />
          </div>
        </div>
      </div>

      {/* Titles */}
      {nextPageData?.assessment_data && (
        <div className="flex items-center justify-between mt-5">
          <p className="font-[500] 2xl:text-[26px] lg:text-[20px] font-roboto text-[#1C1D1E] mb-2">
            {nextPageData?.assessment_data?.name}
          </p>
          <p className="font-[500] 2xl:text-[26px] lg:text-[20px] font-roboto text-[#7A7A7A] mb-2">
            {nextPageData?.assessment_data?.category}
          </p>
        </div>
      )}

      {/* Show Assessment Details and Attempt */}
      {nextPageData && (
        <>
          <div className="max-h-[350px] overflow-y-auto border border-[#DCDCDC] rounded">
            <div className="w-full p-4 bg-backgroundColor">
              <div
                className={`w-full rounded-[10px] bg-white p-6 mt-2 ${
                  JSON.parse(nextPageData?.assessment_data?.headings)?.length >
                    0 && "flex justify-center items-center gap-x-16"
                }`}
              >
                {/* {JSON.parse(nextPageData?.assessment_data?.headings)?.length >
                  0 && (
                  <div className="grid grid-cols-1 gap-x-10 gap-y-8 ">
                    {JSON.parse(nextPageData?.assessment_data?.headings)?.map(
                      (item, index) => (
                        <div
                          className={`${
                            nextPageData?.assessment_data?.columns === 3
                              ? "w-auto"
                              : "w-auto"
                          } h-[80px] flex items-center`}
                          key={index}
                        >
                          <p className="font-[400] text-[20px] text-[#1C1D1E]">
                            {item}
                          </p>
                        </div>
                      )
                    )}
                  </div>
                )} */}

                <div
                  className={`grid ${
                    nextPageData?.assessment_data?.columns === 9
                      ? "grid-cols-9"
                      : nextPageData?.assessment_data?.columns === 5
                      ? "grid-cols-5"
                      : nextPageData?.assessment_data?.columns === 3 &&
                        "grid-cols-3"
                  }  gap-x-10 gap-y-8`}
                >
                  {nextPageData?.assessment_data?.counts?.map((item, index) => (
                    <div
                      className={`relative ${
                        nextPageData?.assessment_data?.columns === 3
                          ? "w-[120px]"
                          : "w-[80px]"
                      } h-[80px] flex justify-center items-center`}
                      key={index}
                    >
                      <input
                        key={index}
                        type="text"
                        value={item}
                        maxLength={1}
                        className={`w-full h-full text-center rounded-[10px]  border ${
                          activeIndex === index
                            ? "border-[#017663] font-bold text-[30px]"
                            : "border-backgroundColor text-[20px] font-normal"
                        }`}
                        placeholder="-"
                        style={{
                          background:
                            answers[index] === item
                              ? "#017663"
                              : answers[index] === " "
                              ? "#AD2126"
                              : "#F9F9FF",
                          color:
                            answers[index] === item
                              ? "white"
                              : answers[index] === " "
                              ? "white"
                              : "#7A7A7A",
                          borderColor:
                            answers[index] === item
                              ? "#017663"
                              : answers[index] === " "
                              ? "#AD2126"
                              : "#F9F9FF"
                        }}
                      />
                      {/* {activeIndex === index &&
                        answers?.length !==
                          nextPageData?.assessment_data?.counts?.length && (
                          <div className="absolute top-[2px] right-[2px]">
                            <MdKeyboardVoice
                              className="text-themeColor bg-red-200 p-1 text-[22px] rounded-full cursor-pointer"
                              onClick={() => {
                                setIsOpenModal(true);
                                setSelectedLetter(item);
                              }}
                            />
                          </div>
                        )} */}
                      {activeIndex >= index && !nextPageData?.is_attempted && (
                        <div className="absolute bottom-0 left-0 flex w-full">
                          <div
                            onClick={() => handleTiTickClick(item, index)}
                            className={`w-[50%] flex justify-center items-center bg-[#F9F9FF] text-[#017663] hover:bg-[#017663] hover:text-white rounded-bl-[10px] py-[2px] cursor-pointer border border-transparent`}
                            style={{
                              borderBottomColor:
                                answers[index] === item
                                  ? "#017663"
                                  : answers[index] === " "
                                  ? "#AD2126"
                                  : "#F9F9FF",
                              borderLeftColor:
                                answers[index] === item
                                  ? "#017663"
                                  : answers[index] === " "
                                  ? "#AD2126"
                                  : "#F9F9FF"
                            }}
                          >
                            <TiTick />
                          </div>
                          <div
                            onClick={() => handleMdCloseClick(item, index)}
                            className={`w-[50%] flex justify-center items-center bg-[#F9F9FF] text-[#AD2126] hover:bg-[#AD2126] hover:text-white rounded-br-[10px] py-[2px] cursor-pointer border border-transparent`}
                            style={{
                              borderBottomColor:
                                answers[index] === item
                                  ? "#017663"
                                  : answers[index] === " "
                                  ? "#AD2126"
                                  : "#F9F9FF",
                              borderRightColor:
                                answers[index] === item
                                  ? "#017663"
                                  : answers[index] === " "
                                  ? "#AD2126"
                                  : "#F9F9FF"
                            }}
                          >
                            <MdClose />
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex flex-col gap-3 mt-4">
                <div className="flex border border-gray-400 bg-white rounded py-3 px-2 w-[35%]">
                  <p className="font-robototo font-[400] text-[16px]">Score:</p>
                  <input
                    type="text"
                    readOnly
                    placeholder={`${
                      correctCounts
                        ? `-------------${correctCounts}`
                        : "---------------"
                    }/${nextPageData?.assessment_data?.total_score}`}
                    className="ml-2 w-[100%] font-robototo font-[400] text-[16px] "
                  />
                </div>
                {/* <div className="flex border border-gray-400 bg-white rounded py-3 px-2 w-[100%]">
                  <p className="font-robototo font-[400] text-[16px] w-[16%]">
                    Skill to review:
                  </p>
                  <input
                    type="text"
                    placeholder="---------------------------------------------------------------------------------------------------------------------------------------------------"
                    className="ml-2 w-[100%] font-robototo font-[400] text-[16px] "
                  />
                </div>
                <div className="flex border border-gray-400 bg-white rounded py-3 px-2 w-[100%]">
                  <p className="font-robototo font-[400] text-[16px] w-[15%]">
                    Skill to teach:
                  </p>
                  <input
                    type="text"
                    placeholder="---------------------------------------------------------------------------------------------------------------------------------------------------"
                    className="ml-2 w-[100%] font-robototo font-[400] text-[16px] "
                  />
                </div> */}

                <div className="flex border border-gray-400 bg-white rounded py-3 px-2 w-[100%]">
                  <p className="font-robototo font-[400] text-[16px] w-[16%]">
                    Skill to review:
                  </p>
                  <input
                    type="text"
                    placeholder="---------------------------------------------------------------------------------------------------------------------------------------------------"
                    value={skillToReview}
                    onChange={(e) => setSkillToReview(e.target.value)}
                    disabled={!isLastPage}
                    className="ml-2 w-[100%] font-robototo font-[400] text-[16px] bg-white"
                  />
                </div>
                <div className="flex border border-gray-400 bg-white rounded py-3 px-2 w-[100%]">
                  <p className="font-robototo font-[400] text-[16px] w-[15%]">
                    Skill to teach:
                  </p>
                  <input
                    type="text"
                    placeholder="---------------------------------------------------------------------------------------------------------------------------------------------------"
                    value={skillToTeach}
                    onChange={(e) => setSkillToTeach(e.target.value)}
                    disabled={!isLastPage}
                    className="ml-2 w-[100%] font-robototo font-[400] text-[16px] bg-white"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-between items-center mt-4">
            {/* {sectionID === 0 ? (
              <div className="flex gap-2 items-center justify-start text-gray-400 font-[700] text-[16px] font-roboto min-w-[120px] h-[45px] cursor-pointer">
                <FaArrowLeft />
                <button>Previous</button>
              </div>
            ) : (
              <div
                onClick={() => {
                  handleClearData();
                  setSectionID(sectionID - 1);
                  submitAndPrevious(sectionID - 1);
                }}
                className="flex gap-2 items-center justify-start text-[#AD2126] font-[700] text-[16px] font-roboto min-w-[120px] h-[45px] cursor-pointer"
              >
                <FaArrowLeft />
                <button>Previous</button>
              </div>
            )} */}

            <div className="flex gap-2 items-center justify-start text-gray-400 font-[700] text-[16px] font-roboto min-w-[120px] h-[45px]">
              <FaArrowLeft />
              <button>Previous</button>
            </div>

            {nextPageData?.assessment_length === sectionID + 1 ? (
              <>
                {nextPageData?.assessment_data?.counts?.length ===
                answers?.length ? (
                  <button
                    className="min-w-[120px] h-[45px] bg-[#017663] rounded-[6px] text-white flex justify-center items-center px-6 cursor-pointer font-[700] text-[16px] font-roboto"
                    onClick={() => {
                      submitAndNext(sectionID, true);
                    }}
                  >
                    Submit
                  </button>
                ) : (
                  <button className="min-w-[120px] h-[45px] bg-gray-300 rounded-[6px] text-gray-500 flex justify-center items-center px-6 font-[700] text-[16px] font-roboto">
                    Submit
                  </button>
                )}
              </>
            ) : (
              <>
                {nextPageData?.assessment_data?.counts?.length ===
                answers?.length ? (
                  <div
                    onClick={() => {
                      handleClearData();
                      setSectionID(sectionID + 1);
                      submitAndNext(sectionID + 1, false);
                    }}
                    className="flex items-center justify-end gap-2 text-[#017663] min-w-[120px] h-[45px] cursor-pointer font-[700] text-[16px] font-roboto"
                  >
                    <button>Next</button>
                    <FaArrowRight />
                  </div>
                ) : (
                  <div className="flex gap-2 items-center justify-end text-gray-400 font-[700] text-[16px] font-roboto min-w-[120px] h-[45px]">
                    <button>Next</button>
                    <FaArrowRight />
                  </div>
                )}
              </>
            )}
          </div>
        </>
      )}

      {/* If Data not found */}
      {!nextPageData && !nextPageLoading && (
        <div className="flex flex-col justify-center items-center gap-2 mt-10 py-2">
          <img src={hand} alt="" />
          <h1 className="mt-2 font-[500] text-[36px] text-[#1C1D1E]">
            Choose Your Preference
          </h1>
          <p className="font-[400] text-[20px] text-[#4E4949]">
            Select Your Preferred Student
          </p>
        </div>
      )}

      {/* Recorder Modal */}
      <AudioRecorder
        setIsOpenModal={setIsOpenModal}
        isOpenModal={isOpenModal}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
        selectedLetter={selectedLetter}
        setSelectedLetter={setSelectedLetter}
        setAnswers={setAnswers}
        setCorrectCounts={setCorrectCounts}
        correctCounts={correctCounts}
        assessment_type={
          nextPageData?.assessment_data?.category ===
            "Reading & Decoding Skills" && "Reading&DecodingSkills"
        }
      />
    </>
  );
}
