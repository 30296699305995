import React from "react";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";

const data = [
  {
    name: "A",
    Benchmark: 6,
    Strategic: 12,
    Intensive: 9
  },
  {
    name: "B",
    Benchmark: 7,
    Strategic: 14,
    Intensive: 11
  },
  {
    name: "C",
    Benchmark: 8,
    Strategic: 16,
    Intensive: 12
  },
  {
    name: "D",
    Benchmark: 9,
    Strategic: 18,
    Intensive: 13
  },
  {
    name: "D",
    Benchmark: 10,
    Strategic: 20,
    Intensive: 14
  },
  {
    name: "E",
    Benchmark: 11,
    Strategic: 22,
    Intensive: 15
  },
  {
    name: "F",
    Benchmark: 12,
    Strategic: 24,
    Intensive: 16
  },
  {
    name: "G",
    Benchmark: 13,
    Strategic: 26,
    Intensive: 17
  },
  {
    name: "H",
    Benchmark: 14,
    Strategic: 28,
    Intensive: 18
  },
  {
    name: "I",
    Benchmark: 15,
    Strategic: 30,
    Intensive: 19
  },
  {
    name: "J",
    Benchmark: 16,
    Strategic: 32,
    Intensive: 20
  },
  {
    name: "K",
    Benchmark: 17,
    Strategic: 34,
    Intensive: 21
  },
  {
    name: "L",
    Benchmark: 18,
    Strategic: 36,
    Intensive: 22
  }
];

function BarGraph({ graphData, selectedFilter }) {
  return (
    <>
      <div className="bg-white rounded-[20px] w-full h-[450px] py-6 px-6 flex justify-center items-center">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={300}
            data={graphData}
            margin={{
              top: 0,
              right: 0,
              left: 0,
              bottom: 0
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="assessment_name" />
            <YAxis />
            <Tooltip />
            <Legend />
            {selectedFilter === "All" && (
              <>
                <Bar
                  dataKey="benchmark"
                  fill="#017663"
                  activeBar={<Rectangle fill="#017663" stroke="#017663" />}
                />
                <Bar
                  dataKey="strategic"
                  fill="#FFC121"
                  activeBar={<Rectangle fill="#FFC121" stroke="#FFC121" />}
                />
                <Bar
                  dataKey="intensive"
                  fill="#AD2126"
                  activeBar={<Rectangle fill="#AD2126" stroke="#AD2126" />}
                />
              </>
            )}
            {selectedFilter === "Benchmark" && (
              <Bar
                dataKey="benchmark"
                fill="#017663"
                activeBar={<Rectangle fill="#017663" stroke="#017663" />}
              />
            )}
            {selectedFilter === "Strategic" && (
              <Bar
                dataKey="strategic"
                fill="#FFC121"
                activeBar={<Rectangle fill="#FFC121" stroke="#FFC121" />}
              />
            )}
            {selectedFilter === "Intensive" && (
              <Bar
                dataKey="intensive"
                fill="#AD2126"
                activeBar={<Rectangle fill="#AD2126" stroke="#AD2126" />}
              />
            )}
          </BarChart>
        </ResponsiveContainer>
      </div>
    </>
  );
}

export default BarGraph;
