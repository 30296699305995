import React from "react";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";

function StudentAnalyticBarGraph({
  showPercentage,
  CalculatePercentage,
  studentAnalyticsResult
}) {
  const data = [
    {
      name: "Fall",
      Class: showPercentage
        ? CalculatePercentage(
            studentAnalyticsResult?.student?.reduce(
              (acc, item) => acc + (item?.total_score || 0),
              0
            ),
            studentAnalyticsResult?.student?.reduce(
              (acc, item) => acc + (item?.Fall || 0),
              0
            )
          )
        : studentAnalyticsResult?.student?.reduce(
            (acc, item) => acc + (item?.Fall || 0),
            0
          ),
      Student: showPercentage
        ? CalculatePercentage(
            studentAnalyticsResult?.student?.reduce(
              (acc, item) => acc + (item?.total_score || 0),
              0
            ),
            studentAnalyticsResult?.student?.reduce(
              (acc, item) => acc + (item?.Fall || 0),
              0
            )
          )
        : studentAnalyticsResult?.student?.reduce(
            (acc, item) => acc + (item?.Fall || 0),
            0
          )
    },
    {
      name: "Winter",
      Class: showPercentage
        ? CalculatePercentage(
            studentAnalyticsResult?.student?.reduce(
              (acc, item) => acc + (item?.total_score || 0),
              0
            ),
            studentAnalyticsResult?.student?.reduce(
              (acc, item) => acc + (item?.Winter || 0),
              0
            )
          )
        : studentAnalyticsResult?.student?.reduce(
            (acc, item) => acc + (item?.Winter || 0),
            0
          ),
      Student: showPercentage
        ? CalculatePercentage(
            studentAnalyticsResult?.student?.reduce(
              (acc, item) => acc + (item?.total_score || 0),
              0
            ),
            studentAnalyticsResult?.student?.reduce(
              (acc, item) => acc + (item?.Winter || 0),
              0
            )
          )
        : studentAnalyticsResult?.student?.reduce(
            (acc, item) => acc + (item?.Winter || 0),
            0
          )
    },
    {
      name: "Spring",
      Class: showPercentage
        ? CalculatePercentage(
            studentAnalyticsResult?.student?.reduce(
              (acc, item) => acc + (item?.total_score || 0),
              0
            ),
            studentAnalyticsResult?.student?.reduce(
              (acc, item) => acc + (item?.Spring || 0),
              0
            )
          )
        : studentAnalyticsResult?.student?.reduce(
            (acc, item) => acc + (item?.Spring || 0),
            0
          ),
      Student: showPercentage
        ? CalculatePercentage(
            studentAnalyticsResult?.student?.reduce(
              (acc, item) => acc + (item?.total_score || 0),
              0
            ),
            studentAnalyticsResult?.student?.reduce(
              (acc, item) => acc + (item?.Spring || 0),
              0
            )
          )
        : studentAnalyticsResult?.student?.reduce(
            (acc, item) => acc + (item?.Spring || 0),
            0
          )
    }
  ];
  return (
    <>
      <div className="bg-white rounded-[20px] w-full h-[500px] py-6 px-6 flex justify-center items-center">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 0,
              right: 0,
              left: 0,
              bottom: 0
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar
              dataKey="Student"
              fill="#4D9F92"
              activeBar={<Rectangle fill="#4D9F92" stroke="#4D9F92" />}
            />
            <Bar
              dataKey="Class"
              fill="#26265F"
              activeBar={<Rectangle fill="#26265F" stroke="#26265F" />}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </>
  );
}

export default StudentAnalyticBarGraph;
