import React, { useEffect, useRef, useState, useCallback } from "react";
import axios from "axios";
import { ButtonLoader_2 } from "./shared/Loaders";
import { getAccessToken } from "../storage/storage";

function StudentsDropdown({
  handleSelectChange,
  selectedStudent,
  assessmentId
}) {
  const token = getAccessToken();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [studentsData, setStudentsData] = useState([]);
  const [studentLoading, setStudentLoading] = useState(true);
  const [studentDataLoading, setStudentDataLoading] = useState(false);
  const [studentMessage, setStudentMessage] = useState(null);
  const [totalPagesStudent, setTotalPagesStudent] = useState(1);
  const [currentPageStudent, setCurrentPageStudent] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState("");
  const dropdownRef = useRef(null);
  const listRef = useRef(null);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const closeDropdown = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", closeDropdown);
    return () => document.removeEventListener("mousedown", closeDropdown);
  }, []);

  const getStudents = useCallback(async () => {
    if (!assessmentId || currentPageStudent > totalPagesStudent) return;

    setStudentDataLoading(true);
    try {
      const response = await axios.get(
        `${BASE_URL}/api/assessment/student?assessment_id=${assessmentId}&page=${currentPageStudent}&name=${query}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.data.results.length > 0) {
        setStudentsData((prevData) =>
          currentPageStudent === 1
            ? response.data.results
            : [...prevData, ...response.data.results]
        );
        if (currentPageStudent === 1) {
          handleSelectChange(response.data.results[0]);
        }
        setStudentMessage(" ");
      } else if (currentPageStudent === 1) {
        setStudentMessage("Students not found in this class & grade");
      }

      setTotalPagesStudent(response.data.num_pages);
    } catch (error) {
      setStudentMessage("Oops! Something went wrong");
    } finally {
      setStudentLoading(false);
      setStudentDataLoading(false);
    }
  }, [
    assessmentId,
    currentPageStudent,
    totalPagesStudent,
    BASE_URL,
    token,
    query
  ]);

  useEffect(() => {
    if (assessmentId) {
      getStudents();
    }
  }, [getStudents, currentPageStudent]);

  useEffect(() => {
    if (assessmentId) {
      setStudentsData([]);
      setCurrentPageStudent(1);
      getStudents();
      setStudentMessage(" ");
    }
  }, [query, assessmentId]);

  useEffect(() => {
    const handleScroll = () => {
      if (listRef.current) {
        const { scrollTop, clientHeight, scrollHeight } = listRef.current;
        if (
          scrollTop + clientHeight >= scrollHeight - 1 &&
          currentPageStudent < totalPagesStudent
        ) {
          setCurrentPageStudent((prev) => prev + 1);
        }
      }
    };

    const listElement = listRef.current;
    if (listElement) listElement.addEventListener("scroll", handleScroll);

    return () => {
      if (listElement) listElement.removeEventListener("scroll", handleScroll);
    };
  }, [currentPageStudent, totalPagesStudent, studentDataLoading]);

  return (
    <div className="relative w-full h-[35px]" ref={dropdownRef}>
      <button
        id="dropdownSearchButton"
        onClick={toggleDropdown}
        className="w-full bg-white outline-none border border-[#DCDCDC] text-[#263238] font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center justify-between hover:border-[#017663] focus-within:border-[#017663]"
        type="button"
      >
        <div className="flex items-center gap-2">
          {selectedStudent?.profile_picture ? (
            <img
              className="w-5 h-5 rounded-full"
              src={`${BASE_URL}/${selectedStudent.profile_picture}`}
              alt=""
            />
          ) : (
            <img
              className="w-5 h-5 rounded-full"
              src="/avatar_dummy.png"
              alt="Student image"
            />
          )}
          {selectedStudent ? selectedStudent.name : "Select Student"}
        </div>
        <svg
          className="w-2.5 h-2.5 ms-3"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m1 1 4 4 4-4"
          />
        </svg>
      </button>

      <div
        id="dropdownSearch"
        className={`${
          isOpen ? "block" : "hidden"
        } z-10 bg-white rounded-lg shadow w-full absolute right-0`}
      >
        <div className="p-3">
          <input
            type="search"
            id="input-group-search"
            className="block w-full p-2  text-sm border border-[#DCDCDC] rounded-lg bg-gray-50 hover:border-[#017663] focus-within:border-[#017663]"
            placeholder="Search"
            value={query}
            onChange={(e) => {
              const value = e.target.value;
              setQuery(value);
            }}
          />
        </div>

        <ul
          ref={listRef}
          className="max-h-40 py-2 overflow-y-auto"
          id="student-dropdown"
        >
          {studentsData?.map((item, index) => (
            <li key={index}>
              <div
                className={`flex items-center gap-2 px-4 py-2 hover:bg-[#ffedee] hover:text-themeColor cursor-pointer ${
                  selectedStudent?.student_id === item.student_id
                    ? "bg-[#ffedee] text-themeColor"
                    : "bg-transparent text-[#1C1D1E]"
                }`}
                onClick={() => {
                  handleSelectChange(item);
                  setIsOpen(false);
                }}
              >
                {item.profile_picture ? (
                  <img
                    className="w-6 h-6 rounded-full"
                    src={`${BASE_URL}/${item.profile_picture}`}
                    alt=""
                  />
                ) : (
                  <img
                    className="w-6 h-6 rounded-full"
                    src="/avatar_dummy.png"
                    alt="Student image"
                  />
                )}
                <p>{item.name}</p>
              </div>
            </li>
          ))}

          {studentDataLoading && (
            <div className="flex justify-center items-center w-full h-full">
              <ButtonLoader_2 />
            </div>
          )}

          {!studentLoading && studentMessage && (
            <div className="flex justify-center items-center w-full h-full">
              <p className="text-gray-600 text-center w-[70%]">
                {studentMessage}
              </p>
            </div>
          )}
        </ul>
      </div>
    </div>
  );
}

export default StudentsDropdown;
